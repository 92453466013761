<template>
  <div style="display: flex">
    <el-select  v-model="caddress.sheng" style="flex: 1;" placeholder="请选择省份" @change="getCityData">
      <el-option
        v-for="item in addressArray"
        :key="item.value"
        :label="item.label"
        :value="item.label+','+item.value"
      >
      </el-option>
    </el-select>
    <el-select  v-model="caddress.shi" style="flex: 1;margin-left: 10px" placeholder="请选择市区" @change="getAreaData">
      <el-option
        v-for="item in cityArray"
        :key="item.value"
        :label="item.label"
        :value="item.label+','+item.value">
      </el-option>
    </el-select>
    <el-select  v-model="caddress.qu" style="flex: 1;margin-left: 10px" placeholder="请选择县" @change="onAreaChanged">
      <el-option
        v-for="item in areaArray"
        :key="item.value"
        :label="item.label"
        :value="item.label+','+item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>

  // 使用说明：v-model时，必须传带有带有省，市，区拼音的字段

  import areaJson from "@/assets/json/pca.data.js"
  export default {
    name: "liAreaSelect",
    //通过 model 选项配置子组件接收的 prop 名以及派发的事件名
    model: {
      prop: 'caddress',
      event: 'change',
    },
    props: {
      caddress: {
        type: Object,
      },
    },
    data() {
      return {
        areaJson:areaJson,
        addressArray: [],//所有数据
        cityArray: [],
        areaArray: [],
      }
    },
    created() {
      this.getAddressData();
    },
    methods: {
      getAddressData() {
        this.addressArray = this.areaJson;
        //默认值赋值获取城市数组
        if(this.caddress.sheng){
          for (let ad of this.addressArray) {
            if (ad.label === this.caddress.sheng.split(',')[0]) {
              this.cityArray = ad.children;
              //---
              //默认赋值获取区域数组
              if(this.caddress.shi){
                for (let area of this.cityArray) {
                  if (area.label === this.caddress.shi.split(',')[0]) {
                    this.areaArray = area.children;
                    break;
                  }
                }
              }
            }
          }
        }
      },
      //选择省份
      getCityData(val) {
        // console.log("AAA",this.caddress);
        //清空市，和区
        this.caddress.shi='';
        this.caddress.qu='';
        this.$emit('change', this.caddress);//发送改变
        for (let ad of this.addressArray) {
          if (ad.label === val.split(',')[0]) {
            this.cityArray = ad.children;
            return
          }
        }
      },
      getAreaData(val) {
        console.log("BBB",this.caddress);
        //清空区
        this.caddress.qu='';
        this.$emit('change', this.caddress);//发送改变
        for (let area of this.cityArray) {
          if (area.label === val.split(',')[0]) {
            this.areaArray = area.children;
            return
          }
        }
      },
      //地区数据变动后
      onAreaChanged() {
        // console.log("CCC",this.caddress);
        this.$emit('change', this.caddress);//发送改变
      }
    }
  }
</script>

<style scoped>

</style>
